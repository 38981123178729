<template>
  <div>
    <a-form layout="vertical" :model="form" v-if="!loading">
      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-item label="Status">
            <a-tag
              class="align-self-center text-center"
              v-if="data.creatorVerificationStatus === 'success'"
              color="success"
              style="margin-bottom:10px"
            >
              <template #icon>
                <check-circle-outlined />
              </template>
              Terverifikasi
            </a-tag>
            <a-tag
              class="align-self-center text-center"
              v-else
              color="error"
              style="margin-bottom:10px"
            >
              <template #icon>
                <close-circle-outlined />
              </template>
              Pending
            </a-tag>
          </a-form-item>
          <a-form-item label="Nama Depan">
            <a-input placeholder="Nama Depan" v-model:value="data.firstName" />
          </a-form-item>
          <a-form-item label="Nama Belakang">
            <a-input placeholder="Nama Belakang" v-model:value="data.lastName" />
          </a-form-item>
          <!-- <a-form-item label="Nomor KTP">
            <a-input placeholder="Nomor KTP" v-model:value="data.ktp" />
          </a-form-item> -->
          <a-form-item label="E-mail">
            <a-input placeholder="Alamat E-mail" v-model:value="data.email" disabled />
          </a-form-item>
          <a-form-item label="Phone">
            <a-input placeholder="Nomer Telepon" v-model:value="data.phoneNumber" disabled />
          </a-form-item>
          <!-- <a-form-item label="Plan">
            <a-select :value="plan">
              <a-select-option value="supporter">
                Supporter
              </a-select-option>
              <a-select-option value="creator">
                Creator
              </a-select-option>
            </a-select>
          </a-form-item> -->
        </a-col>
        <a-col :span="12">
          <a-form-item label="No KTP">
            <a-input placeholder="No KTP" v-model:value="data.idCardNumber" disabled />
          </a-form-item>
          <a-form-item label="Jumlah Coin User">
            <a-input-number
              style="width: 100%;"
              :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
              :parser="value => value.replace(/\$\s?|(,*)/g, '')"
              placeholder="Coin User"
              :value="data.coinUser"
              disabled
            />
          </a-form-item>
          <a-form-item label="Jumlah Ruby">
            <a-input-number
              style="width: 100%;"
              :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
              :parser="value => value.replace(/\$\s?|(,*)/g, '')"
              placeholder="Coin Creator"
              :value="data.coinCreator"
              disabled
            />
          </a-form-item>
          <a-form-item label="Jumlah Point">
            <a-input-number
              style="width: 100%;"
              :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
              :parser="value => value.replace(/\$\s?|(,*)/g, '')"
              placeholder="Jumlah Point"
              disabled
            />
          </a-form-item>
          <a-form-item label="Tanggal Lahir">
            <a-date-picker
              style="width: 100%;"
              placeholder="Tanggal"
              v-model:value="data.birthday"
              format="DD/MM/YYYY"
            />
          </a-form-item>
        </a-col>
        <hr />
        <a-col :span="24">
          <a-form-item label="Akun Bank">
            <a-select class="ml-2" style="width: 100%;" v-model:value="data.bankSelected">
              <a-select-option v-for="(bank, index) in data.bankAccount" :key="index" :value="bank">
                {{ bank.bankName }} {{ bank.accountNumber }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <!-- <a-form-item label="Nomor Akun Bank">
            <a-input placeholder="Bank Account Number" :value="data.bankAccountNumber" />
          </a-form-item> -->
        </a-col>
      </a-row>
      <!-- <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Kanwil">
            <a-select
              v-decorator="[
                'provinsi',
                { rules: [{ required: true, message: 'Kanwil harus diisi!' }] },
              ]"
              placeholder="Pilih kanwil..."
            >
              <a-select-option v-for="(province, i) in provinces" :key="i" :value="province.provinsi">
                  {{province.provinsi}}
               </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Kantah">
            <a-select
              v-decorator="[
                'kabupaten',
                { rules: [{ required: true, message: 'Kantah harus diisi!' }] },
              ]"
              placeholder="Pilih kantah..."
            >
              <a-select-option v-for="(data, i) in cities.kabupaten" :key="i" :value="data">
                  {{data}}
               </a-select-option>
            </a-select>
          </a-form-item> -->
      <button
        class="btn btn-success mr-2 px-5"
        :disabled="submitting"
        @click="() => handleEditAkun()"
      >
        Simpan Data
      </button>
      <button class="btn btn-danger px-5" :disabled="submitting">Hapus User</button>
      <!-- <hr />
      <a-button type="button" class="btn btn-warning px-5 mr-2" :disabled="submitting"
        >Beri Peringatan</a-button
      >
      <a-button type="button" class="btn btn-danger px-5" :disabled="submitting">Ban User</a-button> -->
    </a-form>
    <a-spin v-else tip="Loading...."> </a-spin>
  </div>
</template>

<script>
import moment from 'moment'
import { onMounted, reactive, ref, toRaw } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons-vue'
export default {
  components: {
    CheckCircleOutlined,
    CloseCircleOutlined,
  },
  props: {
    fetchDetail: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const route = useRoute()
    const store = useStore()
    let plan = 'supporter'
    let loading = ref(false)
    let data = reactive({
      firstName: null,
      lastName: null,
      userName: null,
      email: null,
      password: null,
      // ktp: null,
      gender: null,
      coinUser: null,
      coinCreator: null,
      birthday: null,
      bankAccount: [],
      bankSelected: null,
      idCardNumber: null,
    })

    onMounted(() => {
      fetchAccount()
    })

    const fetchAccount = async (search = '') => {
      loading.value = true
      // const rawData = await store.dispatch('category/FETCH_CATEGORY', search)
      let account
      // if (store.state.akun?.accounts.length > 1) {
      //   account = store.state.akun.accounts.filter(el => el._id == route.params.id)[0]
      //   data.firstName = account.firstName
      //   data.lastName = account.lastName
      //   data.userName = account.userName
      //   data.email = account.email
      //   data.creatorVerificationStatus = account.creatorVerificationStatus
      //   data.phoneNumber = account.phoneNumber
      //   data.coinUser = account.userDetail.coinAmount
      //   data.coinCreator = account.userDetail.coinCreatorAmount
      //   const birthday = account.userDetail.birthday.split('/')
      //   data.birthDay = `${birthday[2]}/${birthday[1]}/${birthday[0]}`
      //   data.bankAccount = account.userDetail.bankAccount
      //   data.bankSelected = account.userDetail.bankAccount[0]
      //   console.log(account, '<<<Store')
      // } else {
      await store.dispatch('akun/ADMIN_FETCH_USER_DETAIL', { _id: route.params.id })
      account = store.state.akun.selectedAccount
      data.firstName = account.firstName
      data.lastName = account.lastName
      data.userName = account.userName
      data.email = account.email
      data.creatorVerificationStatus = account.creatorVerificationStatus
      data.phoneNumber = account.phoneNumber
      data.coinUser = account.coinAmount
      data.coinCreator = account.coinCreatorAmount
      data.idCardNumber = account.idCardNumber
      // const fullBirthday = moment(account.birthday, 'DD/MM/YYYY').toDate()
      // const day =
      //   `${fullBirthday.getDate()}`.length < 2
      //     ? `0${fullBirthday.getDate()}`
      //     : fullBirthday.getDate()
      // const month =
      //   `${fullBirthday.getMonth() + 1}`.length < 2
      //     ? `0${fullBirthday.getMonth() + 1}`
      //     : fullBirthday.getMonth() + 1
      // const year = fullBirthday.getFullYear()
      // console.log(`${fullBirthday.getDate()}`.length, '<<<<<<< BIRTHDAY')
      // console.log(day)
      // console.log(month)
      // console.log(year)

      // const birthday = account.birthday.split('/')
      data.birthday = moment(account.birthday, 'DD/MM/YYYY')
      data.bankAccount = account.bankAccount
      data.bankSelected = account.bankAccount[0]
      console.log(account, '<<<Dispatch')
      // }
      loading.value = false
      // data.ktp = account.ktp
      // data.gender = account.gender
    }
    const handleEditAkun = async () => {
      const fullBirthday = data.birthday.toDate()
      const day =
        `${fullBirthday.getDate()}`.length < 2
          ? `0${fullBirthday.getDate()}`
          : fullBirthday.getDate()
      const month =
        `${fullBirthday.getMonth() + 1}`.length < 2
          ? `0${fullBirthday.getMonth() + 1}`
          : fullBirthday.getMonth() + 1
      const year = fullBirthday.getFullYear()
      await store.dispatch('akun/ADMIN_EDIT_USER', {
        _id: route.params.id,
        firstName: data.firstName,
        lastName: data.lastName,
        birthday: `${day}/${month}/${year}`,
      })
      fetchAccount()
      props.fetchDetail()
    }

    let form = reactive({
      name: null,
      nip: null,
      email: null,
      password: null,
    })
    const labelCol = {
      xs: { span: 24 },
      sm: { span: 4 },
    }
    const wrapperCol = {
      xs: { span: 24 },
      sm: { span: 12 },
    }

    return { labelCol, wrapperCol, form, data, plan, handleEditAkun, loading }
  },
}
</script>

<style></style>
